(() => {
  const refs = {
    openModalBtn: document.querySelector('[data-modal-open]'),
    closeModalBtn: document.querySelector('[data-modal-close]'),
    modal: document.querySelector('[data-modal]'),
    modalCheckbox: document.querySelector('[data-modal-checkbox]'), 
    modalForm: document.querySelector('.modal-form'), 
    errorMessage: document.querySelector('.error-message'), 
    userNameInput: document.querySelector('[data-user-name]'), 
    phoneNumberInput: document.querySelector('[data-phone-number]') 
  };

  refs.openModalBtn.addEventListener('click', toggleModal);
  refs.closeModalBtn.addEventListener('click', toggleModal);
  refs.modal.addEventListener('click', modalClose);
  refs.modalForm.addEventListener('submit', handleSubmit); 
  refs.modalCheckbox.addEventListener('change', handleCheckboxChange); 

  function modalClose(e) {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  }

  function toggleModal() {
    refs.modal.classList.toggle('is-hidden');
    if (refs.modal.classList.contains('is-hidden') && refs.modalCheckbox) {
      refs.modalCheckbox.checked = false; 
      refs.userNameInput.value = '';
      refs.phoneNumberInput.value = '';
    }
    refs.errorMessage.style.display = 'none'; 
  }

  function handleSubmit(event) {
    if (!refs.modalCheckbox.checked) {
      event.preventDefault();
      refs.errorMessage.textContent = 'Будь ласка, прийміть умови перед відправкою форми.';
      refs.errorMessage.style.display = 'block'; 
      return;
    }

    if (!refs.userNameInput.value.trim() || !refs.phoneNumberInput.value.trim()) {
      event.preventDefault();
      refs.errorMessage.textContent = 'Будь ласка, заповніть всі обов\'язкові поля.';
      refs.errorMessage.style.display = 'block'; 
      return;
    }

    event.preventDefault(); 
    refs.modalForm.classList.add('submitting');
    setTimeout(() => {
      toggleModal(); 
      refs.modalForm.classList.remove('submitting'); 
    }, 700); 
  }

  function handleCheckboxChange() {
    if (refs.modalCheckbox.checked) {
      refs.errorMessage.style.display = 'none';
    }
  }
})();
